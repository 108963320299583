import { get, put,Delete,post } from '../axios'

// 门店端-积分账户列表
export const integralAccountList = params => get(`/user/web/store/integralAccountList`,params);
// // // 平台端-门店管理-新增
export const integralAccountAdd = params => post(`/user/web/store/integralAccountAdd`,params);
// // // 门店端-积分账户修改
export const integralAccountUpdate = params => put(`/user/web/store/integralAccountUpdate`,params);

// 门店-分组列表
export const goodsClassifyList = params => get(`/goods/web/goodsClassify/store/list`,params);
// 门店端-积分账户详情
export const integralAccountDetail = params => get(`/user/web/store/integralAccountDetail/${params}`);