<template>
  <div>
    <el-drawer
      :with-header="false"
      :size="1000"
      :visible.sync="drawer"
      :before-close="handleClose"  
    >
    <div>
      <div class="head">
          <!-- <div class="full">
            <img class="order_icon" :src="info.avatarUrl||moren" alt="" />
            <div class="text">
              <div class="title">{{ info.nickname }}</div>
            </div>
          </div> -->
          <div class="section">
              <!-- <div class="title">用户信息</div> -->

              <ul class="list">
                <li class="item">
                  <div class="item-title">昵称：</div>
                  <div class="value">{{info.nickname}}</div>
                </li>
                <li class="item">
                  <div class="item-title">姓名：</div>
                  <div class="value">{{info.name}}</div>
                </li>
                <li class="item">
                  <div class="item-title">手机号：</div>
                  <div class="value">{{info.phone}}</div>
                </li>
                <li class="item">
                  <div class="item-title">部门名称：</div>
                  <div class="value">{{info.deptName}}</div>
                </li>
                <li class="item">
                  <div class="item-title">消费金额：</div>
                  <div class="value">{{info.amount}}</div>
                </li>
                <li class="item">
                  <div class="item-title">门店：</div>
                  <div class="value">{{info.storeName}}</div>
                </li>
                <li class="item">
                  <div class="item-title">身份：</div>
                  <div class="value">{{info.identity ==1?'普通用户':'企业会员'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">消费金额：</div>
                  <div class="value">{{info.amount}}</div>
                </li>
                <li class="item">
                  <div class="item-title">创建时间：</div>
                  <div class="value">{{info.createTime}}</div>
                </li>
              </ul>
            </div>
        </div>
       </div>
    </el-drawer>
  </div>
</template>

<script>
// import { userOrderApi, userDetailApi, userCouponApi, userBillApi,
//   modifyUserRefLog, userPointsApi, userSignLogApi, userHistoryApi, memberGrowthLog, userEditApi } from '@/api/user'
// import { verifyEmail } from '@/utils/toolsValidate';
export default {
  name: "UserDetails",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      moren: require("@/assets/images/f.png"),
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("closeDrawer");
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 33%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;
  &:before {
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title {
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }
  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img {
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th {
  background: #f0f5ff;
}
</style>
