<template>
  <div class="divBox">
    <div class="selCard">
      <el-form :model="tableFrom" ref="searchForm" inline size="small" label-width="85px">
        <div>
          <el-form-item label="账户名称：" prop="name">
            <el-input placeholder="请输入账户名称" v-model="tableFrom.name" class="input-with-select selWidth" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="tableFrom.pageNum=1;getList()">搜索</el-button>
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-card class="mt14">
      <div class="mb20 marginBottom20">
        <el-button size="small" type="primary" class="mt5" @click="onAdd">添加账户</el-button>
      </div>
      <el-table v-loading="listLoading" :data="tableData.data" size="small" highlight-current-row>
        <el-table-column label="序号" type="index" width="150">
        </el-table-column>
        <el-table-column label="积分账户名称" prop="name" min-width="150">
        </el-table-column>




        <el-table-column prop="type" label="积分适用类型" min-width="120">
          <template slot-scope="{ row }">
            <span>{{ row.type == 1 ? "全部商品可用" : row.type == 2 ? "部分商品可用" : '部分分类可用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="积分开始时间" prop="startDate" min-width="150">
        </el-table-column>
        <el-table-column label="积分结束时间" prop="validityDate" min-width="150">
        </el-table-column>
        <el-table-column label="操作" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button icon="el-icon-edit" type="text" size="small" @click="onEdit(scope.row)">编辑</el-button>
            <el-button icon="el-icon-upload2" type="text" size="small" @click="importUser(scope.row)">导入用户</el-button>
            <el-button icon="el-icon-download" type="text" size="small" @click="exportUser(scope.row)">导出明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination background :page-size="tableFrom.pageSize" :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper" :total="tableData.total" @size-change="handleSizeChange"
          @current-change="pageChange" />
      </div>
    </el-card>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
      <!-- <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form> -->
    </el-dialog>

    <!--积分账户详情-->
    <IntegralDetails @closeDrawer="closeDrawer" :drawer="drawer" :info="info" ref="userDetails" />
    <el-drawer title="设置积分" :size="800" :visible.sync="integralShow" direction="rtl"
      :before-close="() => { integralShow = false }">
      <el-form ref="accountForm" size="small" :rules="integralrule" :model="accountForm" label-width="120px"
        @submit.native.prevent>
        <!-- 索引 -->
        <el-form-item label="账户名称：" prop="name">
          <el-input size="small" v-model="accountForm.name" placeholder="请输入" maxlength="20" class="selWidth" />
        </el-form-item>
        <el-form-item label="有效时间：" prop="dataTime">
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" v-model="accountForm.dataTime"
                    type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
          <!-- <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd hh:mm:ss"
            v-model="accountForm.validityDate" type="date" placeholder="选择日期">
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="账户类型：" prop="type">
          <el-radio-group @change="typeChange" v-model="accountForm.type">
            <el-radio :label="1">全部商品可用</el-radio>
            <el-radio :label="2">部分商品可用</el-radio>
            <el-radio :label="3">部分分类可用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="accountForm.type == 3" label="商品分类：" prop="goodsClassify">
          <el-cascader v-model="accountForm.goodsClassify" :options="storeList" :props="classifyProps"
            clearable></el-cascader>
        </el-form-item>
        <el-card  v-if="accountForm.type == 2" class="mt14">


          <el-table ref="goodTable" v-loading="listLoading" @select-all="handleSelectAll" @select="selectARow"
            :data="goodData.data" size="small">
            <el-table-column key="2" type="selection" width="55" />
            <el-table-column label="商品图" min-width="70">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image style="width: 36px; height: 36px" :src="scope.row.mainImg"
                    :preview-src-list="[scope.row.mainImg]" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="store_name" label="商品名称" min-width="200">
              <template slot-scope="scope">
                <div>
                  <span class="tags_name" :style="scope.row.specType == 0
        ? 'color: #ff8a4d;'
        : 'color: #4073FA;'
        " :class="'name' + scope.row.spec_type">{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span>{{
        scope.row.name || "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="brandName" label="品牌名称" min-width="150" />
            <el-table-column prop="salePrice" label="商品售价(元)" min-width="80" />
            <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
            <el-table-column prop="stock" label="库存" min-width="70" />
          </el-table>
          <div class="block">
            <div>已经选择{{ multipleSelection.length }}</div>
            <el-pagination background :page-size="goodTableFrom.pageSize" :current-page="goodTableFrom.page"
              layout="total, prev, pager, next, jumper" :total="goodData.total" @size-change="goodSizeChange"
              @current-change="goodpageChange" />
          </div>
        </el-card>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button @click="() => { integralShow = false }">取消</el-button>
        <el-button type="primary" :loading="showLoadingForm" @click="integralEdit(accountForm)">确认</el-button>
      </div>
    </el-drawer>
    <Import v-if="importShow" :show="importShow" :action="importAction" :downloadLink="importDownloadLink"
      :problemLink="importProblem" @close="importClose" />
  </div>
</template>
<script>
import {
  goodsStorePageList,
} from "@/api/goods/goods";
import {
  integralAccountList,
  integralAccountAdd,
  integralAccountUpdate,
  goodsClassifyList,
  integralAccountDetail
} from "@/api/store/integral";
import moment from 'moment';
import { exportExcel } from "@/assets/utils/exportExcel";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import IntegralDetails from "./integralDetails.vue";
import Import from "./comments/import.vue";
export default {
  name: "UserList",
  components: {
    FormImgUpload,
    IntegralDetails,
    Import
  },
  data() {
    return {
      // 部分商品相关的数据开始
      dialogGoodsVisible: false,
      goodData: {
        data: [],
        total: 0,
      },
      goodTableFrom: {
        pageNum: 1,
        pageSize: 5,
        checkStatus: "1",
        status: "",
      },
      multipleSelection: [],
      // merCateList: [],
      goodListLoading: false,
      selectionId: [],
      // 部分商品相关的数据结束
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;   //禁用以前的日期，今天不禁用
          // return date.getTime() <= Date.now();    //禁用今天以及以前的日期
        }
      },
      classifyProps: {
        multiple: true,
        label: 'name',
        value: 'id'
      },
      integralShow: false,
      loading: false, //抽屉动画
      showLoadingForm: false,
      integraObj: {},//当前处理的积分门店
      integraDisabled: false,
      integralrule: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        // dataTime: [{ required: true, message: "请选择", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      accountForm: {
        name: '',
        type: 1,
        dataTime: '',
        goodsClassify: ''
        
      },
      moren: require("@/assets/images/f.png"),
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        name: '',
        // phone: '',
        // time: [],
        // storeId: '',
        // name: '',
        // identity: ''
      },
      groupList: [],
      visible: false,
      drawer: false,
      title: "新增用户",
      info: {},
      dialogVisible: false,
      storeList: [], //门店列表
      form: {
        image: [],
        isShow: 0,
        type: 1,
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "部门",
            prop: "deptId",
            type: "cascader",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            checkStrictly: true,
            props: { label: "name", value: "id" },
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "change",
              },
            ],
          },
          {
            label: "姓名",
            prop: "name",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "phone",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      
      importShow: false,
      importAction: '',
      importDownloadLink: '',
      importProblem: '',
    };
  },
  mounted() {
    this.getList();
    this.getStoreList();
    // this.getGoodList()
  },
  methods: {
    // 导出
    exportUser(item){
      // window.open(`/api/web/clientUser/integralAccount/export/${item.id}`, '_self') 
      exportExcel(`/user/web/clientUser/integralAccount/export/${item.id}`, {
        responseType: "blob",
        exportExcelName:'导入积分明细'
      });
    },
    // 导入
    importUser(item) {
      // console.log('批量导入房源')
      this.importAction = '/api/user/web/clientUser/integral/import/'+item.id;
      this.importDownloadLink = '';
      this.importProblem = '/api/user/web/clientUser/integral/error/export';
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false
      if (e === 1 || e === 2) {
        this.getList();
      }
    },
    // 部分商品相关方法来时
    typeChange(e){
      if(e==2){
        this.getGoodList()
      }
      console.log(e);
    },
    getGoodList() {
      console.log(this.multipleSelection,'选中的商品');
      this.goodListLoading = true;
      goodsStorePageList(this.goodTableFrom)
        .then((res) => {
          this.goodData.data = res.data.records;
          this.goodData.total = res.data.total;
          this.goodListLoading = false;
          res.data.records.map((item, index) => {
            this.multipleSelection.map((val, k) => {
              if (item.id == val.id) {
                this.$nextTick(() => {
                  this.$refs.goodTable.toggleRowSelection(item);
                })
              }
            })
          })
          // this.goodData.total = res.data.total;
          // this.goodListLoading = false;
        })
        .catch((res) => {
          this.goodListLoading = false;
        });
    },
    selectARow(val, row) { //单选Checkbox的事件
      let selectBool = val.length && val.indexOf(row) !== -1   //当前点击是否勾选
      if (!selectBool) {  //取消勾选
        this.multipleSelection.forEach((item, i) => {
          if (item.id == row.id) {
            this.multipleSelection.splice(i, 1)
          }
        })

      } else { //选中
        this.multipleSelection.push(row)
      }
    },
    handleSelectAll(val) {  //全选 Checkbox的事件
      if (val.length == this.goodData.data.length) { //全选
        this.multipleSelection = this.multipleSelection.concat(val)
        //去重
        let obj = {}
        let result = []
        this.multipleSelection.forEach(item => {
          if (!obj[item.id]) {
            result.push(item)
            obj[item.id] = true
          }
        })
        this.multipleSelection = result

      } else { //取消全选
        this.goodData.data.forEach(item => {
          this.multipleSelection.forEach((multItem, i) => {
            if (item.id == multItem.id) {
              this.multipleSelection.splice(i, 1)
            }
          })
        })
      }
    },
    //  //多选得id
    //  handleSelectionChange(val) {
    //   this.multipleSelection = val;
    //   console.log(val);
    //   const data = [];
    //   this.multipleSelection.map((item) => {
    //     data.push(item.id);
    //   });
    //   this.selectionId = data;
    //   // console.log(this.selectionId);
    // },
    goodSizeChange(val) {
      this.goodTableFrom.pageSize = val;
      this.getGoodList();
    },
    goodpageChange(val) {
      this.goodTableFrom.pageNum = val;
      this.getGoodList();
    },
    // 部分商品想法方法结束
    onEdit(item) {
      this.showLoadingForm=false
      integralAccountDetail(item.id).then(({ code, data }) => {
        if (code == 200) {
          this.integralShow = true;
          if (data.type==3) {
            data.goodsClassify = JSON.parse(data.goodsClassify)
            this.multipleSelection=[]
            // console.log(JSON.parse(data.goodsClassify));
          }
          if(data.type==2){
              let multipleList=[]
              data.goodsClassify.split(',').map(item=>{
                multipleList.push({id:item})
              })
              this.multipleSelection=multipleList
              // this.getGoodList();
            }
          this.getGoodList();
          console.log(data);
          this.$nextTick(() => {
            this.accountForm = {...data,dataTime:[data.startDate,data.validityDate]}
            console.log(this.accountForm);
          })
        }
      })
      console.log(item);
      this.integralShow = true;
      // this.accountForm=item
      // this.integralShow = true;

    },
    onAdd() {
      this.integralShow = true;
      this.showLoadingForm=false
      this.multipleSelection=[]
      this.getGoodList()
      this.$nextTick(() => {
        this.$refs['accountForm'].resetFields()
      })
    },
    integralEdit(accountForm) {
      this.$refs.accountForm.validate(async (valid, done) => {
        if (valid) {
          let goodsClassify = ''
          if (accountForm.goodsClassify) {
            goodsClassify = JSON.stringify(accountForm.goodsClassify)
          }
          console.log(goodsClassify);
          if(accountForm.type==2){
            if(this.multipleSelection.length){
              let goodId=[]
              this.multipleSelection.map(item=>{
                goodId.push(item.id)
              })
              goodsClassify=goodId.join(',')
            }else{
              this.$message.warning("请选择商品");
              return
            }
            console.log(this.multipleSelection);
          }
          // return
          this.showLoadingForm = true;
          if (accountForm.id) {
            const { code, data } = await integralAccountUpdate({
              id: accountForm.id,
              goodsClassify: goodsClassify,
              name: accountForm.name,
              type: accountForm.type,
              startDate:accountForm.dataTime[0],
              validityDate: accountForm.dataTime[1]
            })
            if (code == 200) {
              this.getList();
              this.integralShow = false
              this.$message.success("操作成功");
            }
            this.showLoadingForm = false
          } else {
            const { code, data } = await integralAccountAdd({
              goodsClassify: goodsClassify,
              name: accountForm.name,
              type: accountForm.type,
              startDate:accountForm.dataTime[0],
              validityDate: accountForm.dataTime[1]
            })
            if (code == 200) {
              this.getList();
              this.integralShow = false
              this.$message.success("操作成功");
            }
            this.showLoadingForm = false
          }

        }
      });
    },
    // uploadDelete() {},
    // uploadBefore(file, done, loading, column) {
    //   if (file.type === "" || column.accept.indexOf(file.type) < 0) {
    //     this.$message.error("文件格式有误");
    //     loading();
    //   } else {
    //     done();
    //   }
    // },
    // uploadError(error, column) {
    //   this.$message.error(error);
    // },
    // uploadAfter(res, done) {
    //   done();
    // },
    // upload(e) {
    //   if (Array.isArray(e)) {
    //     this.form.image = [...e];
    //   } else {
    //     this.form.image = [...this.form.image, e];
    //   }
    // },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = []
      this.tableFrom.pageNum=1
      this.getList();
    },
    //获取门店接口
    getStoreList() {
      goodsClassifyList({}).then((res) => {
        this.storeList = res.data;
      });
    },
    createUser() {
      this.dialogVisible = true;
      this.getStore();
    },
    //用户详情抽屉框
    onDetails(row) {
      this.info = row;
      this.drawer = true;
    },
    closeDrawer() {
      this.drawer = false;
    },
    getList() {
      this.listLoading = true;
      
      // if (this.tableFrom.time.length > 0) {
      //   this.tableFrom.startTime = moment(this.tableFrom.time[0]).format('YYYY-MM-DD HH:mm:ss');
      //   this.tableFrom.endTime = moment(this.tableFrom.time[1]).format('YYYY-MM-DD') + ' 23:59:59';
      // } else {
      //   this.tableFrom.startTime = ''
      //   this.tableFrom.endTime = ''

      // }
      integralAccountList({ ...this.tableFrom, })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    formSubmit() { },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/styles/form.scss";

.check {
  color: #00a2d4;
}

.dia {
  /deep/ .el-dialog__body {
    height: 700px !important;
  }
}

.text-right {
  text-align: right;
}

.minwidth {
  display: inline-block;
  max-width: 200px;
  line-height: 20px;
}

.search-form {
  display: flex;
  justify-content: space-between;

  .search-form-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  a {
    color: var(--prev-color-primary);
  }
}

.selWidth {
  width: 250px !important;
}

.search-form-sub {
  display: flex;
}

.container {
  min-width: 821px;

  /deep/.el-form-item {
    width: 100%;
  }

  /deep/.el-form-item__content {
    width: 72%;
  }
}

.vipName {
  color: #dab176;
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--prev-color-primary);
  font-size: 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}

/deep/.el-date-editor.el-input {
  width: 100%;
}

/deep/[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none !important;
}

/deep/.el-input-group__prepend .el-input {
  width: 100px;
}

.demo-drawer__footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #ddd;
  padding: 10px 30px;
  display: flex;
  justify-content: right;
  width: 100%;
  background: #ffffff;
}
.block{
  display: flex;
 align-items: center;
 justify-content: space-between;
}
</style>